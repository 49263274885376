<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center px-2>
      <v-flex xs12>
        <ProductDetails
          v-bind:productData="productData"
          v-bind:productPhotos="productPhotos"
          :Sizes="Sizes"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ProductDetails from "./productDetails";
export default {
  props: ["id"],
  components: {
    ProductDetails,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      productData: {},
      productPhotos: [],
      Sizes:[],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/viewStore",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.params.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.productData = response.data.data;
          this.productPhotos = this.productData.photos;
          this.Sizes=this.productData.size;
          this.loadContent = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>