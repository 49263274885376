<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />

    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md5 lg5 xl4>
        <v-card outlined tile height="100%">
          <v-layout wrap justify-center fill-height>
            <v-flex xs12 align-self-center>
              <span class="hidden-md-and-up">
                <v-img
                  v-if="!curImage"
                  :src="mediaURL + productPhotos[0]"
                  max-height="60vh"
                  contain
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF2323"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img
                  v-if="curImage"
                  :src="mediaURL + curImage"
                  max-height="60vh"
                  contain
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF2323"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </span>
              <span class="hidden-sm-and-down">
                <!-- <image-magnifier v-if="!curImage" :src="mediaURL + productPhotos[0]"
                  :zoom-src="mediaURL + productPhotos[0]" width="400" height="300" zoom-width="400"
                  zoom-height="300"></image-magnifier> -->
                <inner-image-zoom
                  v-if="!curImage"
                  zoomType="hover"
                  :hideHint="true"
                  :zoomPreload="true"
                  :src="mediaURL + productPhotos[0]"
                  :zoomSrc="mediaURL + productPhotos[0]"
                >
                </inner-image-zoom>
                <!-- <image-magnifier v-if="curImage" :src="mediaURL + curImage" :zoom-src="mediaURL + curImage" width="400"
                  height="300" zoom-width="400" zoom-height="300"></image-magnifier> -->
                <inner-image-zoom
                  v-if="curImage"
                  zoomType="hover"
                  :hideHint="true"
                  :zoomPreload="true"
                  :src="mediaURL + curImage"
                  :zoomSrc="mediaURL + curImage"
                >
                </inner-image-zoom>
              </span>
            </v-flex>
            <v-flex xs11>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex xs2 v-for="(item, i) in productData.photos" :key="i">
                    <v-card @click="showImage(item)" flat tile>
                      <v-layout wrap justify-center>
                        <v-flex pa-2>
                          <v-img :src="mediaURL + item" height="100px" contain>
                            <template v-slot:placeholder>
                              <ImageLoader />
                            </template>
                          </v-img>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md7 lg7 xl8>
        <v-layout wrap justify-center pt-3>
          <v-flex xs11>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinsbold;
                    font-size: 22px;
                    color: #000000;
                  "
                >
                  {{ productData.productname }}
                </span>
              </v-flex>
              <v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinslight;
                    font-size: 15px;
                    color: #979797;
                  "
                >
                  {{ productData.caption }}
                </span>
              </v-flex>
              <v-flex xs12 text-left pt-4>
                <v-btn
                  small
                  depressed
                  color="#30B868"
                  tile
                  v-if="productData.offerPercentage"
                >
                  <span
                    style="
                      font-family: poppinsmedium;
                      font-size: 12px;
                      color: #ffffff;
                    "
                  >
                    {{ productData.offerPercentage }}%
                  </span>
                </v-btn>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: poppinssemibold;
                            font-size: 27px;
                            color: #000000;
                          "
                        >
                          Rs. {{ productData.offerPrice }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          class="text-decoration-line-through"
                          style="
                            font-family: poppinsregular;
                            font-size: 16px;
                            color: #ff0000;
                          "
                        >
                          Rs. {{ productData.price }}
                        </span>

                        <span
                          v-if="productData.instock"
                          class="pl-4"
                          style="
                            font-family: poppinsregular;
                            font-size: 14px;
                            color: #52d25b;
                          "
                        >
                          In Stock
                        </span>
                        <span
                          v-if="!productData.instock"
                          class="pl-4"
                          style="
                            font-family: poppinsregular;
                            font-size: 14px;
                            color: #ff4343;
                          "
                        >
                          Out of Stock
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 text-left align-self-center>
                    <v-layout wrap justify-start align-content-center>
                      <v-flex xs2 text-right px-2 align-self-center>
                        <span
                          style="
                            font-family: poppinsregular;
                            font-size: 14px;
                            color: #8f8f8f;
                          "
                          class="caption"
                        >
                          {{ productData.totalRating }}
                        </span>
                      </v-flex>
                      <v-flex xs8 text-left px-2 align-self-center>
                        <v-rating
                          v-model="productData.totalRating"
                          color="#FFD341"
                          background-color="#e0e0e0"
                          empty-icon="$ratingFull"
                          half-increments
                          readonly
                          hover
                          small
                        ></v-rating>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 text-left py-4>
                <span
                  v-for="(item, i) in Sizes"
                  :key="i"
                  class="px-1"
                >
                  <span v-if="item.size">
                    <v-btn
                      small
                      light
                      rounded
                      depressed
                      outlined
                      @click="changeSize(item)"
                      :ripple="false"
                      :color="
                        productSize.size == item.size ? '#FF0000' : '#919191'
                      "
                      class="sizebutton px-2"
                    >
                      {{ item.size }}
                    </v-btn>
                  </span>
                </span>
              </v-flex>
              <!-- <v-flex xs12 text-left v-if="selectedProduct.stock">
                <span
                  style="
                    font-family: poppinsmedium;
                    font-size: 12px;
                    color: #ff0000;
                  "
                >
                  {{ selectedProduct.stock }} Left
                </span>
              </v-flex> -->
              <v-flex xs12 text-left pt-2 v-if="productSize.stock">
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left v-if="productSize.color">
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 16px;
                        color: #000000;
                      "
                    >
                      COLOR
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-1 v-if="productSize.color">
                  <v-flex
                    xs2
                    sm2
                    md1
                    lg1
                    xl1
                    align-self-center
                    v-for="(item, i) in productSize.stock"
                    :key="i"
                    px-2
                  >
                    <v-card
                      outlined
                      rounded="50px"
                      class="colorselector"
                      width="40px"
                      @click="selectProduct(item)"
                      :color="
                        selectedProduct.color == item.color ? '#ffebeb' : ''
                      "
                    >
                      <v-layout py-2 wrap align-content-center>
                        <v-flex xs12 text-center>
                          <v-avatar :color="item.color" size="15" />
                        </v-flex>
                        <v-flex xs12 style="padding-top: 2px" text-center>
                          <span
                            style="
                              font-family: poppinsmedium;
                              font-size: 12px;
                              color: #808080;
                            "
                            >{{ item.stock }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 text-left pt-4>
                <v-layout wrap justify-start>
                  <v-flex xs8 sm7 md6 lg4 xl3 tex-left>
                    <v-text-field
                      ref="promoCode"
                      color="#EC0000"
                      placeholder="Add Promo Code"
                      hide-details
                      outlined
                      dense
                      v-model="promoCode"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 sm5 md4 lg3 xl2 px-4 text-left align-self-center>
                    <v-btn :ripple="false" block tile outlined color="#4B4B4B">
                      <span
                        style="
                          font-family: poppinsmedium;
                          font-size: 12px;
                          color: #4b4b4b;
                        "
                      >
                        APPLY
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex> -->
              <v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinslight;
                    font-size: 14px;
                    color: #707070;
                  "
                >
                  {{ productData.description }}
                </span>
              </v-flex>
              <v-flex xs12 sm6 md6 lg7 pl-xl-10 xl8>
                <v-layout
                  v-if="
                    productData.materialused &&
                    productData.materialused.length > 0
                  "
                  wrap
                  justify-center
                  pt-4
                >
                  <v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinsbold;
                        font-size: 22px;
                        color: #000000;
                      "
                    >
                      MATERIAL USED
                    </span>
                  </v-flex>
                  <v-flex
                    xs12
                    text-left
                    v-for="(item, i) in productData.materialused"
                    :key="i"
                  >
                    <span
                      style="
                        font-family: poppinsregular;
                        font-size: 14px;
                        color: #474747;
                      "
                    >
                      {{ item }}
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center pt-4 v-if="productData.sellerId">
                  <v-flex xs12 text-left text-uppercase>
                    <span
                      style="
                        font-family: poppinsbold;
                        font-size: 22px;
                        color: #000000;
                      "
                    >
                      Retailer
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinsregular;
                        font-size: 14px;
                        color: #474747;
                      "
                    >
                      {{ productData.sellerId.shopName }},
                      {{ productData.sellerId.address.locality }},
                      {{ productData.sellerId.address.city }},
                      <br />
                      {{ productData.sellerId.address.state }},
                      {{ productData.sellerId.address.pincode }}
                    </span>
                  </v-flex>
                  <!-- <v-flex xs12 text-left pt-4 v-if="productData.seller">
            <router-link
              :to="
                '/Shops/' +
                productData.seller._id +
                '/' +
                productData.seller.organization
              "
            >
              <v-chip style="cursor: pointer">
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 12px;
                    color: #000000;
                  "
                >
                  Visit Profile
                </span>
              </v-chip>
            </router-link>
          </v-flex> -->
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 pt-4>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm6 md6 lg4 xl2 pr-2>
                    <v-btn
                      :ripple="false"
                      depressed
                      dark
                      block
                      tile
                      color="#30B868"
                    >
                      <span
                        style="
                          font-family: poppinssemibold;
                          font-size: 12px;
                          text-transform: none;
                        "
                      >
                        <v-icon size="20">mdi-cart-outline</v-icon>
                        Add to Bag
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg4 xl2 pl-2>
                    <v-btn
                      :ripple="false"
                      depressed
                      outlined
                      block
                      tile
                      color="#4B4B4B"
                    >
                      <span
                        style="
                          font-family: poppinssemibold;
                          font-size: 12px;
                          color: #312828;
                          text-transform: none;
                        "
                      >
                        <span>
                          <v-icon size="20">mdi-bookmark-outline</v-icon>
                          Add to wishlist
                        </span>
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex> -->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm6 md6 lg5 xl4>
        <v-chip-group column>
          <v-chip v-for="(item, i) in productData.tags" :key="i">
            {{ item }}
          </v-chip>
        </v-chip-group>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
export default {
  // props: ["productData", "productPhotos","Sizes"],
  components: {
    // ImageZoomer,
    "inner-image-zoom": InnerImageZoom,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      msg: null,
      colors: [
        { color: "#C2C2C2", quantity: 23 },
        { color: "#EE2749", quantity: 87 },
        { color: "#000000", quantity: 12 },
        { color: "#5E0C0C", quantity: 32 },
        { color: "#4251D1", quantity: 241 },
        { color: "#4251D1", quantity: 241 },
      ],
      promoCode: null,
      productSize: {},
      selectedProduct: {},
      curImage: null,
      productData: {},
      productPhotos: [],
      Sizes: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/viewStore",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.params.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.productData = response.data.data;
          this.productPhotos = this.productData.photos;
          this.Sizes = this.productData.size;
          if (this.Sizes) {
            if (this.productData.size.length > 0) {
              this.changeSize(this.productData.size[0]);
              this.productSize == this.productData.size[0].size;
              this.productData.instock = this.productData.size[0].instock;
              this.productData.size = this.productData.size[0].size;
              this.productData.totalRating == this.productData.size[0].totalRating;
              this.productData.offerPrice == this.productData.size[0].offerPrice;
              this.productData.price == this.productData.size[0].price;
              this.productData.offerPercentage == this.productData.size[0].offerPercentage;
            }
          }
          this.loadContent = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(item) {
      this.curImage = item;
    },
    changeSize(item) {
      this.productSize = item;
      this.productData.offerPrice = item.offerPrice;
      this.productData.price = item.price;
      this.productData.instock = item.instock;
      this.productData.totalRating = item.totalRating;
      this.productData.offerPercentage = item.offerPercentage;
    },
    selectProduct(item) {
      this.selectedProduct = item;
    },
  },
};
</script>
<style scoped>
.colorselector.v-sheet.v-card {
  border-radius: 35px;
}

.sizebutton.v-btn:not(.v-btn--round).v-size--small {
  /* min-width: 50px; */
  padding: 0 12px !important;
  min-width: 30px !important;
}
</style>